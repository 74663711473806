import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { createRoot } from 'react-dom/client';

import CircularLoaders from '../../components/loaders';
import Item from './Item';

import cn from '../../utils/classNames';
import styles from './styles/index.module.scss';
import AjaxButton from '../../components/ajaxButton';

const EMPTY_CALLS_MESSAGE = 'За последние 30 минут звонков не поступало';
const TITLE_PAGE = 'Звонки за последние 30 минут';
const BUTTON_UPDATE = 'Обновить';

const callsTableHeaders = [
  {
    name: 'order', text: '#', byCenter: false,
  },
  {
    name: 'uid', text: 'UID', byCenter: false,
  },
  {
    name: 'time', text: 'Время звонка', byCenter: false,
  },
  {
    name: 'number', text: 'Номер', byCenter: false,
  },
  {
    name: 'full_name', text: 'ФИО', byCenter: false,
  },
  {
    name: 'employee', text: 'Карточка Сотрудника', byCenter: true,
  },
  {
    name: 'account', text: 'Аккаунт Сотрудника', byCenter: true,
  },
];

class CallsComponent extends Component {
  static propTypes = { callsService: PropTypes.object.isRequired };

  constructor(props) {
    super();
    const { calls, isRequesting } = props.callsService.get();
    this.state = {
      calls,
      startLoading: true,
      updateLoading: isRequesting,
    };
  }

  componentDidMount() {
    const { callsService } = this.props;

    this.unsubscribeCallsServiceCb = callsService.subscribe(this.updateCalls);

    callsService.initCalls();
  }

  componentWillUnmount() {
    this.unsubscribeCallsServiceCb();
  }

  updateCalls = ({ calls, isRequesting }) => {
    this.setState({
      calls,
      startLoading: false,
      updateLoading: isRequesting,

    });
  };

  getClassesTableHeader = (name, byCenter) => cn({
    [styles.col_1_9]: name === 'order' || name === 'uid',
    [styles.table_col]: true,
    [styles.table_header]: true,
    [styles.col_by_centr]: byCenter,
    [styles.col_1_5]: name === 'full_name',
  });

  handleUpdateCalls = () => this.props.callsService.updateCalls();

  renderLoading = (isInTable = false) => {
    if (isInTable) {
      return (
        <div className={ styles.table_no_list_wraper }>
          <CircularLoaders wrapWidth={ 64 } />
        </div>
      );
    }

    return <CircularLoaders wrapClassName='content-loader-wrap' />;
  };

  renderEmptyList = () => (
    <div className={ `empty-list ${styles.table_no_list_wraper}` }>
      { EMPTY_CALLS_MESSAGE }
    </div>
  );

  renderTableHeader = () => (
    <ul className={ styles.table_row }>
      { callsTableHeaders.map(({
        name, text, byCenter,
      }) => (
        <li key={ name } className={ this.getClassesTableHeader(name, byCenter) }>{ text }</li>
      )) }
    </ul>
  );

  renderButton = () => (
    <div className={ styles.button_wrap }>
      <AjaxButton
        loading={ this.state.updateLoading }
        disabled={ this.state.updateLoading }
        onClick={ this.handleUpdateCalls }
        theme={ `sw-button ${styles.button}` }
        label={ BUTTON_UPDATE }
      />
    </div>
  );

  renderTableBody = () => {
    const { calls } = this.state;

    return calls.map(({
      UID, Time, PhoneNumber, FullName, AccountId, CompanyId, EmployeeId,
    }, i) => (
      <div key={ UID } className={ styles.table_item }>
        <Item
          time={ Time }
          phoneNumber={ PhoneNumber }
          fullName={ FullName }
          accountId={ AccountId }
          companyId={ CompanyId }
          employeeId={ EmployeeId }
          orderNumber={ i + 1 }
          uId={ UID }
        />
      </div>
    ));
  };

  renderTable = () => {
    const { calls, updateLoading } = this.state;
    const IS_IN_TABLE = true;

    const list = calls.length ? this.renderTableBody() : this.renderEmptyList();
    const tableContentHtml = updateLoading ? this.renderLoading(IS_IN_TABLE) : list;

    return (
      <div className={ styles.table }>
        { this.renderTableHeader() }
        { tableContentHtml }
      </div>
    );
  };

  render() {
    const { startLoading } = this.state;

    if (startLoading) {
      return this.renderLoading();
    }

    return (
      <div className={ styles.wrap }>
        <h1 className={ styles.title }>{ TITLE_PAGE }</h1>
        { this.renderButton() }
        { this.renderTable() }
      </div>
    );
  }
}

const renderComponents = (box, opts) => {
  const root = createRoot(box);

  root.render(
    <CallsComponent
      callsService={ opts.callsService }
    />,
  );

  return root;
};

export default renderComponents;
